.ms-container{
	background: transparent url('/img/switch.png') no-repeat 50% 50%;
	width: 100%;
}

.ms-container .ms-list {
    border: 1px solid #e6e6e6 !important;
    box-shadow: none !important;
    height: 280px !important;
}
.ms-container .ms-selectable li.ms-elem-selectable, .ms-container .ms-selection li.ms-elem-selection {
    padding: 5px 10px !important;
}

.ms-container:after{
	content: ".";
	display: block;
	height: 0;
	line-height: 0;
	font-size: 0;
	clear: both;
	min-height: 0;
	visibility: hidden;
}

.ms-container .ms-selectable, .ms-container .ms-selection{
	background: #fff;
	color: #555555;
	float: left;
	width: 45%;
}
.ms-container .ms-selection{
	float: right;
}

.ms-container .ms-list{
	-webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
	-moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
	box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
	-webkit-transition: border linear 0.2s, box-shadow linear 0.2s;
	-moz-transition: border linear 0.2s, box-shadow linear 0.2s;
	-ms-transition: border linear 0.2s, box-shadow linear 0.2s;
	-o-transition: border linear 0.2s, box-shadow linear 0.2s;
	transition: border linear 0.2s, box-shadow linear 0.2s;
	border: 1px solid #ccc;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	border-radius: 3px;
	position: relative;
	height: 200px;
	padding: 0;
	overflow-y: auto;
}

.ms-container .ms-list.ms-focus{
	border-color: rgba(82, 168, 236, 0.8);
	-webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(82, 168, 236, 0.6);
	-moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(82, 168, 236, 0.6);
	box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(82, 168, 236, 0.6);
	outline: 0;
	outline: thin dotted \9;
}

.ms-container ul{
	margin: 0;
	list-style-type: none;
	padding: 0;
}

.ms-container .ms-optgroup-container{
	width: 100%;
}

.ms-container .ms-optgroup-label{
	margin: 0;
	padding: 5px 0px 0px 5px;
	cursor: pointer;
	color: #999;
}

.ms-container .ms-selectable li.ms-elem-selectable,
.ms-container .ms-selection li.ms-elem-selection{
	border-bottom: 1px #eee solid;
	padding: 2px 10px;
	color: #555;
	font-size: 14px;
}

.ms-container .ms-selectable li.ms-hover,
.ms-container .ms-selection li.ms-hover{
	cursor: pointer;
	color: #fff;
	text-decoration: none;
	background-color: #08c;
}

.ms-container .ms-selectable li.disabled,
.ms-container .ms-selection li.disabled{
	background-color: #eee;
	color: #aaa;
	cursor: text;
}
