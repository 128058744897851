ul.acl-tree-view {
  padding: 0;
  display: block;
  overflow: auto;
}
ul.acl-tree-view ul {
  padding: 0 0 0 0.65em;
  border-left: 5px solid #6a6e71;
  margin-left: 5px;
  display: block;
  overflow: auto;
}
ul.acl-tree-view li {
  list-style-type: none;
  display: block;
}
ul.acl-tree-view li a {
  display: block;
  padding: 0.2em 0.2em 0.2em 0.4em;
  margin-top: 3px;
  color: #000;
  -moz-border-radius-topleft: 0.3em;
  -moz-border-radius-bottomleft: 0.3em;
  -webkit-border-top-left-radius: 0.3em;
  -webkit-border-bottom-left-radius: 0.3em;
  border-top-left-radius: 0.3em;
  border-bottom-left-radius: 0.3em;
  -webkit-transition: all 0.1s linear;
  transition: all 0.1s linear;
  background-color: #f2f2f2;
  font-size: 12pt;
}
ul.acl-tree-view li a.acl-tree-view-toggle > i:first-child {
  -webkit-transition: -webkit-transform 0.2s;
  -webkit-transition: all 200ms ease-in-out;
  transition: all 200ms ease-in-out;
}
ul.acl-tree-view li a.acl-tree-view-toggle > span > i:first-child {
  margin-left: 0.15em;
}
ul.acl-tree-view li a > span.acl-tree-view-description {
  display: block;
  font-size: 10pt;
  color: #6a6e71;
  padding: 0.2em 0.4em;
  background-color: rgba(0, 0, 0, 0.05);
  -moz-border-radius-topleft: 0.3em;
  -moz-border-radius-bottomleft: 0.3em;
  -webkit-border-top-left-radius: 0.3em;
  -webkit-border-bottom-left-radius: 0.3em;
  border-top-left-radius: 0.3em;
  border-bottom-left-radius: 0.3em;
  border: 1px solid rgba(0, 0, 0, 0.03);
}
ul.acl-tree-view li a.acl-tree-view-toggle.acl-tree-view-active {
  background-color: #112d31;
  color: #fff;
  display: block;
  text-shadow: 1px 0 1px rgba(0, 0, 0, 0.5);
}
ul.acl-tree-view
  li
  a.acl-tree-view-toggle.acl-tree-view-active
  > i:first-child {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}
ul.acl-tree-view
  li
  a.acl-tree-view-toggle.acl-tree-view-active
  > span.acl-tree-view-description {
  color: rgba(255, 255, 255, 0.7);
  border: 1px solid rgba(0, 0, 0, 0.2);
}
ul.acl-tree-view li a:hover {
  text-decoration: none;
  text-shadow: 1px 0 1px rgba(0, 0, 0, 0.3);
  color: #fff;
}
ul.acl-tree-view li a:not(.acl-tree-view-toggle):focus,
ul.acl-tree-view li a:not(.acl-tree-view-toggle):visited {
  background-color: #264e58;
  text-decoration: none;
  text-shadow: 1px 0 1px rgba(0, 0, 0, 0.3);
  color: #fff;
}
ul.acl-tree-view
  li
  a:not(.acl-tree-view-toggle):focus
  > span.acl-tree-view-description,
ul.acl-tree-view
  li
  a:not(.acl-tree-view-toggle):visited
  > span.acl-tree-view-description {
  color: rgba(255, 255, 255, 0.7);
  border: 1px solid rgba(0, 0, 0, 0.1);
}
ul.acl-tree-view li a:not(.acl-tree-view-active):hover {
  background-color: #264e58;
}
ul.acl-tree-view
  li
  a:not(.acl-tree-view-active):hover
  > span.acl-tree-view-description {
  color: rgba(255, 255, 255, 0.7);
  border: 1px solid rgba(0, 0, 0, 0.1);
}
