header .brand {
    text-align: left;
}

header .header-image {
    max-height: 40px;
    max-width: 220px;
    padding-left: 10px;
}

.page-content {
    min-height: initial;
    padding-bottom: 20px;
}


.btn-group.btn-group-actions {
    display: inline-flex;
}

.btn-group.btn-group-actions a {
    line-height: 100%;
}



.navigation ul.onlyOneChild li:first-child:before {
    height: 20px;
}

.navigation a.hideCollapse[aria-expanded=true]:after {
    display: none;
}

body {
    overflow-y: scroll;
}


select.form-control {
    padding-top: 3px;
    padding-bottom: 0;
}



.table-link {
    color: var(--gray-dark);
    display: block;
    padding-left: 18px;
    position: relative;
}

.table-link i {
    position: absolute;
    left: 0px;
    top: 2px;
}

.file-input-error {
    width: calc(100% - 12px);
}


/* tooltip + fix za tooltipe na wizardu */

.tooltip-help {
    cursor: pointer;
}

.content fieldset {
    padding-bottom: 20px !important;
}

/* helper classes */

.block {
    display: block !important
}

.inline-block {
    display: inline-block !important
}

.inline {
    display: inline !important
}

.flex {
    display: flex !important
}

.inline-flex {
    display: inline-flex !important
}

.flex-row {
    flex-direction: row !important
}

.flex-row-reverse {
    flex-direction: row-reverse !important
}

.flex-col {
    flex-direction: column !important
}

.flex-col-reverse {
    flex-direction: column-reverse !important
}

.flex-wrap {
    flex-wrap: wrap !important
}

.flex-wrap-reverse {
    flex-wrap: wrap-reverse !important
}

.flex-no-wrap {
    flex-wrap: nowrap !important
}

.place-items-auto {
    place-items: auto !important
}

.place-items-start {
    place-items: start !important
}

.place-items-end {
    place-items: end !important
}

.place-items-center {
    place-items: center !important
}

.place-items-stretch {
    place-items: stretch !important
}

.place-content-center {
    place-content: center !important
}

.place-content-start {
    place-content: start !important
}

.place-content-end {
    place-content: end !important
}

.place-content-between {
    place-content: space-between !important
}

.place-content-around {
    place-content: space-around !important
}

.place-content-evenly {
    place-content: space-evenly !important
}

.place-content-stretch {
    place-content: stretch !important
}

.place-self-auto {
    place-self: auto !important
}

.place-self-start {
    place-self: start !important
}

.place-self-end {
    place-self: end !important
}

.place-self-center {
    place-self: center !important
}

.place-self-stretch {
    place-self: stretch !important
}

.items-start {
    align-items: flex-start !important
}

.items-end {
    align-items: flex-end !important
}

.items-center {
    align-items: center !important
}

.items-baseline {
    align-items: baseline !important
}

.items-stretch {
    align-items: stretch !important
}

.content-center {
    align-content: center !important
}

.content-start {
    align-content: flex-start !important
}

.content-end {
    align-content: flex-end !important
}

.content-between {
    align-content: space-between !important
}

.content-around {
    align-content: space-around !important
}

.content-evenly {
    align-content: space-evenly !important
}

.self-auto {
    align-self: auto !important
}

.self-start {
    align-self: flex-start !important
}

.self-end {
    align-self: flex-end !important
}

.self-center {
    align-self: center !important
}

.self-stretch {
    align-self: stretch !important
}

.justify-items-auto {
    justify-items: auto !important
}

.justify-items-start {
    justify-items: start !important
}

.justify-items-end {
    justify-items: end !important
}

.justify-items-center {
    justify-items: center !important
}

.justify-items-stretch {
    justify-items: stretch !important
}

.justify-start {
    justify-content: flex-start !important
}

.justify-end {
    justify-content: flex-end !important
}

.justify-center {
    justify-content: center !important
}

.justify-between {
    justify-content: space-between !important
}

.justify-around {
    justify-content: space-around !important
}

.justify-evenly {
    justify-content: space-evenly !important
}

.justify-self-auto {
    justify-self: auto !important
}

.justify-self-start {
    justify-self: start !important
}

.justify-self-end {
    justify-self: end !important
}

.justify-self-center {
    justify-self: center !important
}

.justify-self-stretch {
    justify-self: stretch !important
}

.flex-1 {
    flex: 1 1 0% !important
}

.flex-auto {
    flex: 1 1 auto !important
}

.flex-initial {
    flex: 0 1 auto !important
}

.flex-none {
    flex: none !important
}

.flex-grow-0 {
    flex-grow: 0 !important
}

.flex-grow {
    flex-grow: 1 !important
}

.flex-shrink-0 {
    flex-shrink: 0 !important
}

.flex-shrink {
    flex-shrink: 1 !important
}

.text-xs {
    font-size: .75rem !important
}

.text-sm {
    font-size: .875rem !important
}

.text-base {
    font-size: 1rem !important
}

.text-lg {
    font-size: 1.125rem !important
}

.text-xl {
    font-size: 1.25rem !important
}

.text-2xl {
    font-size: 1.5rem !important
}

.text-3xl {
    font-size: 1.875rem !important
}

.text-4xl {
    font-size: 2.25rem !important
}

.text-5xl {
    font-size: 3rem !important
}

.text-6xl {
    font-size: 4rem !important
}

.m-0 {
    margin: 0 !important
}

.m-1 {
    margin: 0.25rem !important
}

.m-2 {
    margin: 0.5rem !important
}

.m-3 {
    margin: 0.75rem !important
}

.m-4 {
    margin: 1rem !important
}

.m-5 {
    margin: 1.25rem !important
}

.m-6 {
    margin: 1.5rem !important
}

.m-8 {
    margin: 2rem !important
}

.m-10 {
    margin: 2.5rem !important
}

.m-12 {
    margin: 3rem !important
}

.m-16 {
    margin: 4rem !important
}

.m-20 {
    margin: 5rem !important
}

.m-24 {
    margin: 6rem !important
}

.m-32 {
    margin: 8rem !important
}

.m-auto {
    margin: auto !important
}

.m-px {
    margin: 1px !important
}

.m-base {
    margin: 2.2rem !important
}

.-m-px {
    margin: -1px !important
}

.-m-1 {
    margin: -0.25rem !important
}

.-m-2 {
    margin: -0.5rem !important
}

.-m-3 {
    margin: -0.75rem !important
}

.-m-4 {
    margin: -1rem !important
}

.my-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important
}

.mx-0 {
    margin-left: 0 !important;
    margin-right: 0 !important
}

.my-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important
}

.mx-1 {
    margin-left: 0.25rem !important;
    margin-right: 0.25rem !important
}

.my-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important
}

.mx-2 {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important
}

.my-3 {
    margin-top: 0.75rem !important;
    margin-bottom: 0.75rem !important
}

.mx-3 {
    margin-left: 0.75rem !important;
    margin-right: 0.75rem !important
}

.my-4 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important
}

.mx-4 {
    margin-left: 1rem !important;
    margin-right: 1rem !important
}

.my-5 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important
}

.mx-5 {
    margin-left: 1.25rem !important;
    margin-right: 1.25rem !important
}

.my-6 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important
}

.mx-6 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important
}

.my-8 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important
}

.mx-8 {
    margin-left: 2rem !important;
    margin-right: 2rem !important
}

.my-10 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important
}

.mx-10 {
    margin-left: 2.5rem !important;
    margin-right: 2.5rem !important
}

.my-12 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important
}

.mx-12 {
    margin-left: 3rem !important;
    margin-right: 3rem !important
}

.my-16 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important
}

.mx-16 {
    margin-left: 4rem !important;
    margin-right: 4rem !important
}

.my-20 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important
}

.mx-20 {
    margin-left: 5rem !important;
    margin-right: 5rem !important
}

.my-24 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important
}

.mx-24 {
    margin-left: 6rem !important;
    margin-right: 6rem !important
}

.my-32 {
    margin-top: 8rem !important;
    margin-bottom: 8rem !important
}

.mx-32 {
    margin-left: 8rem !important;
    margin-right: 8rem !important
}

.my-auto {
    margin-top: auto !important;
    margin-bottom: auto !important
}

.mx-auto {
    margin-left: auto !important;
    margin-right: auto !important
}

.my-px {
    margin-top: 1px !important;
    margin-bottom: 1px !important
}

.mx-px {
    margin-left: 1px !important;
    margin-right: 1px !important
}

.my-base {
    margin-top: 2.2rem !important;
    margin-bottom: 2.2rem !important
}

.mx-base {
    margin-left: 2.2rem !important;
    margin-right: 2.2rem !important
}

.-my-px {
    margin-top: -1px !important;
    margin-bottom: -1px !important
}

.-mx-px {
    margin-left: -1px !important;
    margin-right: -1px !important
}

.-my-1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important
}

.-mx-1 {
    margin-left: -0.25rem !important;
    margin-right: -0.25rem !important
}

.-my-2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important
}

.-mx-2 {
    margin-left: -0.5rem !important;
    margin-right: -0.5rem !important
}

.-my-3 {
    margin-top: -0.75rem !important;
    margin-bottom: -0.75rem !important
}

.-mx-3 {
    margin-left: -0.75rem !important;
    margin-right: -0.75rem !important
}

.-my-4 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important
}

.-mx-4 {
    margin-left: -1rem !important;
    margin-right: -1rem !important
}

.mt-0 {
    margin-top: 0 !important
}

.mr-0 {
    margin-right: 0 !important
}

.mb-0 {
    margin-bottom: 0 !important
}

.ml-0 {
    margin-left: 0 !important
}

.mt-1 {
    margin-top: 0.25rem !important
}

.mr-1 {
    margin-right: 0.25rem !important
}

.mb-1 {
    margin-bottom: 0.25rem !important
}

.ml-1 {
    margin-left: 0.25rem !important
}

.mt-2 {
    margin-top: 0.5rem !important
}

.mr-2 {
    margin-right: 0.5rem !important
}

.mb-2 {
    margin-bottom: 0.5rem !important
}

.ml-2 {
    margin-left: 0.5rem !important
}

.mt-3 {
    margin-top: 0.75rem !important
}

.mr-3 {
    margin-right: 0.75rem !important
}

.mb-3 {
    margin-bottom: 0.75rem !important
}

.ml-3 {
    margin-left: 0.75rem !important
}

.mt-4 {
    margin-top: 1rem !important
}

.mr-4 {
    margin-right: 1rem !important
}

.mb-4 {
    margin-bottom: 1rem !important
}

.ml-4 {
    margin-left: 1rem !important
}

.mt-5 {
    margin-top: 1.25rem !important
}

.mr-5 {
    margin-right: 1.25rem !important
}

.mb-5 {
    margin-bottom: 1.25rem !important
}

.ml-5 {
    margin-left: 1.25rem !important
}

.mt-6 {
    margin-top: 1.5rem !important
}

.mr-6 {
    margin-right: 1.5rem !important
}

.mb-6 {
    margin-bottom: 1.5rem !important
}

.ml-6 {
    margin-left: 1.5rem !important
}

.mt-8 {
    margin-top: 2rem !important
}

.mr-8 {
    margin-right: 2rem !important
}

.mb-8 {
    margin-bottom: 2rem !important
}

.ml-8 {
    margin-left: 2rem !important
}

.mt-10 {
    margin-top: 2.5rem !important
}

.mr-10 {
    margin-right: 2.5rem !important
}

.mb-10 {
    margin-bottom: 2.5rem !important
}

.ml-10 {
    margin-left: 2.5rem !important
}

.mt-12 {
    margin-top: 3rem !important
}

.mr-12 {
    margin-right: 3rem !important
}

.mb-12 {
    margin-bottom: 3rem !important
}

.ml-12 {
    margin-left: 3rem !important
}

.mt-16 {
    margin-top: 4rem !important
}

.mr-16 {
    margin-right: 4rem !important
}

.mb-16 {
    margin-bottom: 4rem !important
}

.ml-16 {
    margin-left: 4rem !important
}

.mt-20 {
    margin-top: 5rem !important
}

.mr-20 {
    margin-right: 5rem !important
}

.mb-20 {
    margin-bottom: 5rem !important
}

.ml-20 {
    margin-left: 5rem !important
}

.mt-24 {
    margin-top: 6rem !important
}

.mr-24 {
    margin-right: 6rem !important
}

.mb-24 {
    margin-bottom: 6rem !important
}

.ml-24 {
    margin-left: 6rem !important
}

.mt-32 {
    margin-top: 8rem !important
}

.mr-32 {
    margin-right: 8rem !important
}

.mb-32 {
    margin-bottom: 8rem !important
}

.ml-32 {
    margin-left: 8rem !important
}

.mt-auto {
    margin-top: auto !important
}

.mr-auto {
    margin-right: auto !important
}

.mb-auto {
    margin-bottom: auto !important
}

.ml-auto {
    margin-left: auto !important
}

.mt-px {
    margin-top: 1px !important
}

.mr-px {
    margin-right: 1px !important
}

.mb-px {
    margin-bottom: 1px !important
}

.ml-px {
    margin-left: 1px !important
}

.mt-base {
    margin-top: 2.2rem !important
}

.mr-base {
    margin-right: 2.2rem !important
}

.mb-base {
    margin-bottom: 2.2rem !important
}

.ml-base {
    margin-left: 2.2rem !important
}

.-mt-px {
    margin-top: -1px !important
}

.-mr-px {
    margin-right: -1px !important
}

.-mb-px {
    margin-bottom: -1px !important
}

.-ml-px {
    margin-left: -1px !important
}

.-mt-1 {
    margin-top: -0.25rem !important
}

.-mr-1 {
    margin-right: -0.25rem !important
}

.-mb-1 {
    margin-bottom: -0.25rem !important
}

.-ml-1 {
    margin-left: -0.25rem !important
}

.-mt-2 {
    margin-top: -0.5rem !important
}

.-mr-2 {
    margin-right: -0.5rem !important
}

.-mb-2 {
    margin-bottom: -0.5rem !important
}

.-ml-2 {
    margin-left: -0.5rem !important
}

.-mt-3 {
    margin-top: -0.75rem !important
}

.-mr-3 {
    margin-right: -0.75rem !important
}

.-mb-3 {
    margin-bottom: -0.75rem !important
}

.-ml-3 {
    margin-left: -0.75rem !important
}

.-mt-4 {
    margin-top: -1rem !important
}

.-mr-4 {
    margin-right: -1rem !important
}

.-mb-4 {
    margin-bottom: -1rem !important
}

.-ml-4 {
    margin-left: -1rem !important
}

.opacity-0 {
    opacity: 0 !important
}

.opacity-25 {
    opacity: 0.25 !important
}

.opacity-50 {
    opacity: 0.5 !important
}

.opacity-75 {
    opacity: 0.75 !important
}

.opacity-100 {
    opacity: 1 !important
}

.p-0 {
    padding: 0 !important
}

.p-1 {
    padding: 0.25rem !important
}

.p-2 {
    padding: 0.5rem !important
}

.p-3 {
    padding: 0.75rem !important
}

.p-4 {
    padding: 1rem !important
}

.p-5 {
    padding: 1.25rem !important
}

.p-6 {
    padding: 1.5rem !important
}

.p-8 {
    padding: 2rem !important
}

.p-10 {
    padding: 2.5rem !important
}

.p-12 {
    padding: 3rem !important
}

.p-16 {
    padding: 4rem !important
}

.p-20 {
    padding: 5rem !important
}

.p-24 {
    padding: 6rem !important
}

.p-32 {
    padding: 8rem !important
}

.p-px {
    padding: 1px !important
}

.p-base {
    padding: 2.2rem !important
}

.py-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important
}

.px-0 {
    padding-left: 0 !important;
    padding-right: 0 !important
}

.py-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important
}

.px-1 {
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important
}

.py-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important
}

.px-2 {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important
}

.py-3 {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important
}

.px-3 {
    padding-left: 0.75rem !important;
    padding-right: 0.75rem !important
}

.py-4 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important
}

.px-4 {
    padding-left: 1rem !important;
    padding-right: 1rem !important
}

.py-5 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important
}

.px-5 {
    padding-left: 1.25rem !important;
    padding-right: 1.25rem !important
}

.py-6 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important
}

.px-6 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important
}

.py-8 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important
}

.px-8 {
    padding-left: 2rem !important;
    padding-right: 2rem !important
}

.py-10 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important
}

.px-10 {
    padding-left: 2.5rem !important;
    padding-right: 2.5rem !important
}

.py-12 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important
}

.px-12 {
    padding-left: 3rem !important;
    padding-right: 3rem !important
}

.py-16 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important
}

.px-16 {
    padding-left: 4rem !important;
    padding-right: 4rem !important
}

.py-20 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important
}

.px-20 {
    padding-left: 5rem !important;
    padding-right: 5rem !important
}

.py-24 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important
}

.px-24 {
    padding-left: 6rem !important;
    padding-right: 6rem !important
}

.py-32 {
    padding-top: 8rem !important;
    padding-bottom: 8rem !important
}

.px-32 {
    padding-left: 8rem !important;
    padding-right: 8rem !important
}

.py-px {
    padding-top: 1px !important;
    padding-bottom: 1px !important
}

.px-px {
    padding-left: 1px !important;
    padding-right: 1px !important
}

.py-base {
    padding-top: 2.2rem !important;
    padding-bottom: 2.2rem !important
}

.px-base {
    padding-left: 2.2rem !important;
    padding-right: 2.2rem !important
}

.pt-0 {
    padding-top: 0 !important
}

.pr-0 {
    padding-right: 0 !important
}

.pb-0 {
    padding-bottom: 0 !important
}

.pl-0 {
    padding-left: 0 !important
}

.pt-1 {
    padding-top: 0.25rem !important
}

.pr-1 {
    padding-right: 0.25rem !important
}

.pb-1 {
    padding-bottom: 0.25rem !important
}

.pl-1 {
    padding-left: 0.25rem !important
}

.pt-2 {
    padding-top: 0.5rem !important
}

.pr-2 {
    padding-right: 0.5rem !important
}

.pb-2 {
    padding-bottom: 0.5rem !important
}

.pl-2 {
    padding-left: 0.5rem !important
}

.pt-3 {
    padding-top: 0.75rem !important
}

.pr-3 {
    padding-right: 0.75rem !important
}

.pb-3 {
    padding-bottom: 0.75rem !important
}

.pl-3 {
    padding-left: 0.75rem !important
}

.pt-4 {
    padding-top: 1rem !important
}

.pr-4 {
    padding-right: 1rem !important
}

.pb-4 {
    padding-bottom: 1rem !important
}

.pl-4 {
    padding-left: 1rem !important
}

.pt-5 {
    padding-top: 1.25rem !important
}

.pr-5 {
    padding-right: 1.25rem !important
}

.pb-5 {
    padding-bottom: 1.25rem !important
}

.pl-5 {
    padding-left: 1.25rem !important
}

.pt-6 {
    padding-top: 1.5rem !important
}

.pr-6 {
    padding-right: 1.5rem !important
}

.pb-6 {
    padding-bottom: 1.5rem !important
}

.pl-6 {
    padding-left: 1.5rem !important
}

.pt-8 {
    padding-top: 2rem !important
}

.pr-8 {
    padding-right: 2rem !important
}

.pb-8 {
    padding-bottom: 2rem !important
}

.pl-8 {
    padding-left: 2rem !important
}

.pt-10 {
    padding-top: 2.5rem !important
}

.pr-10 {
    padding-right: 2.5rem !important
}

.pb-10 {
    padding-bottom: 2.5rem !important
}

.pl-10 {
    padding-left: 2.5rem !important
}

.pt-12 {
    padding-top: 3rem !important
}

.pr-12 {
    padding-right: 3rem !important
}

.pb-12 {
    padding-bottom: 3rem !important
}

.pl-12 {
    padding-left: 3rem !important
}

.pt-16 {
    padding-top: 4rem !important
}

.pr-16 {
    padding-right: 4rem !important
}

.pb-16 {
    padding-bottom: 4rem !important
}

.pl-16 {
    padding-left: 4rem !important
}

.pt-20 {
    padding-top: 5rem !important
}

.pr-20 {
    padding-right: 5rem !important
}

.pb-20 {
    padding-bottom: 5rem !important
}

.pl-20 {
    padding-left: 5rem !important
}

.pt-24 {
    padding-top: 6rem !important
}

.pr-24 {
    padding-right: 6rem !important
}

.pb-24 {
    padding-bottom: 6rem !important
}

.pl-24 {
    padding-left: 6rem !important
}

.pt-32 {
    padding-top: 8rem !important
}

.pr-32 {
    padding-right: 8rem !important
}

.pb-32 {
    padding-bottom: 8rem !important
}

.pl-32 {
    padding-left: 8rem !important
}

.pt-px {
    padding-top: 1px !important
}

.pr-px {
    padding-right: 1px !important
}

.pb-px {
    padding-bottom: 1px !important
}

.pl-px {
    padding-left: 1px !important
}

.pt-base {
    padding-top: 2.2rem !important
}

.pr-base {
    padding-right: 2.2rem !important
}

.pb-base {
    padding-bottom: 2.2rem !important
}

.pl-base {
    padding-left: 2.2rem !important
}
